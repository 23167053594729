import Vue from 'vue'
import Vuex from 'vuex'
import VueRouter from 'vue-router'
import { storeConfig } from '@/store'
import i18n from '@/utils/plugins/i18n'
import globalGuards from '@/router/globalGuards'
import errorHandler from '@/router/errorHandler'
import routes from '@/router/routes'
import JWT from 'mocks/jwt'
import ProfileMock from 'mocks/profile'
import ProjectMock from 'mocks/Project'
import OrgMock from 'mocks/Organization'

/** Create a store using the application store modules.
 *
 * @returns {Vuex} - The vuex instance
 */
export function createStore ({ loggedIn = true, orgCanonical = 'seraf', hasProject = false } = {}) {
  if (_.has(arguments?.[0], 'cloneStoreConfig')) console.error('[createStore] cloneStoreConfig is no longer used, please remove it.')

  const { VUE_APP_API_URL, NODE_ENV, VUE_APP_VERSION } = process.env
  const store = new Vuex.Store(_.cloneDeep(storeConfig))

  const jwt = JWT.VALID()

  if (loggedIn) {
    const organization = new OrgMock({ name: orgCanonical })
    store.state.dev.showDevThings = true
    store.state.organizations = [organization, new OrgMock({ name: `${orgCanonical}-2` })]
    store.state.organization.detail = organization
    store.state.user.profile = new ProfileMock({ username: JWT.username })
    store.state.auth.jwt = jwt
    store.state.customers.scopes[0] = organization
    if (hasProject) store.state.organization.project.detail = new ProjectMock('my project', { owner: JWT.username })
    sessionStorage.setItem(LSK.ORGANIZATION, JSON.stringify(organization))
    localStorage.setItem(LSK.ORGANIZATION, JSON.stringify(organization))
    localStorage.setItem(LSK.JWT, jwt)
    localStorage.setItem(LSK.APP_VERSION, VUE_APP_VERSION)
    localStorage.setItem(LSK.ENVIRONMENT, `[env: ${NODE_ENV}]---[api: ${VUE_APP_API_URL}]`)
  } else {
    store.state.organizations = []
    store.state.organization.detail = null
    store.state.auth.jwt = null
    sessionStorage.clear()
    localStorage.clear()
  }

  return store
}

/** Get an i18n instance, merged global and component messages
 *
 * @param   {Object} [Component] - The component to populate non-global i18n.messages
 *
 * @returns {Object}          - The i18n instance
 */
export function createI18n (component) {
  if (!component) console.error('[createI18n] was not passed a component')
  if (!component?.i18n?.messages) return i18n
  return {
    ...i18n,
    locale: 'en',
    messages: _.merge(i18n.messages, component.i18n.messages),
  }
}

/** Create the router object which we use in the application
 * using all the helper functions which create defaults applications dependencies
 *
 * @param {Vuex} [store] - The vuex instance to use
 * @param {vue-i18n} [i18n] - The i18n instance to use
 * @param {Object} [opts] - The options required by the router
 * @returns {VueRouter~Router} - The VueRouter Router instance
 */
export function createRouter (store = createStore(), { useGlobalGuards = true } = {}) {
  Vue.use(VueRouter)

  const router = new VueRouter({
    mode: 'abstract',
    base: '/',
    linkActiveClass: 'router-link-active',
    routes,
  })

  if (useGlobalGuards) {
    const { beforeEach, afterEach } = globalGuards(store)
    beforeEach.forEach((params) => router.beforeEach(params))
    afterEach.forEach((params) => router.afterEach(params))
  }

  router.onError(errorHandler)

  return router
}
